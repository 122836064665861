<template>
  <div>
    <b-card
      id="customer-form-create"
      ref="customer-form-create"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <div class="d-flex flex-column">
          <b-button
            v-b-tooltip.hover="'Tên khách hàng, SĐT'"
            :variant="getStatusCheckDuplicateBtn()"
            size="sm"
            @click="handleCheckCustomerUnique"
          >
            <feather-icon icon="AlertCircleIcon" />
            Kiểm tra
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <!--              nhóm sản phẩm: nhà phố | khách sạn & chdv-->
            <!--            <dynamic-form-->
            <!--              :id="'filter-form-nhom-san-pham'"-->
            <!--              :value="value.product_type"-->
            <!--              :type="'radio'"-->
            <!--              :label="'Nhóm sản phẩm'"-->
            <!--              :class="'form-group-hor'"-->
            <!--              :options="PRODUCT_TYPE.filter(item => item.validBy.includes('group1'))"-->
            <!--              @update-value="val => value.product_type = val"-->
            <!--            />-->

            <!--              hình thức-->
            <dynamic-form
              :id="'customer-form-hinh-thuc'"
              :value="value.formality"
              :type="'radio'"
              :label="'Hình thức'"
              :required="required.formality ? 'required' : ''"
              :class="'form-group-hor'"
              :options="FORMALITY_CUSTOMER"
              @update-value="val => value.formality = val"
            />

            <!--              loại-->
            <dynamic-form
              :id="'customer-form-loai'"
              :value="value.type"
              :type="'select'"
              :label="'Loại'"
              :required="required.type ? 'required' : ''"
              :class="'form-group-hor'"
              :options="TYPE_PRODUCT.filter(item => item.validBy.includes(value.formality || 'thue'))"
              @update-value="val => value.type = val"
            />

            <!--              quận huyện-->
            <dynamic-form
              :id="'filter-form-quanhuyen'"
              :value="value.district_ids"
              :type="'multi-select'"
              :label="'Quận/Huyện'"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="districts"
              :select-default="false"
              @update-value="val => value.district_ids = val"
            />

            <!--              Phường/Xã-->
            <dynamic-form
              :id="'customer-form-phuongxa'"
              :value="value.ward_ids"
              :type="'multi-select'"
              :label="'Phường/Xã'"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="wardsByDistricts"
              :select-default="false"
              :clearable="true"
              @update-value="val => value.ward_ids = val"
            />

            <!--              Đường-->
            <dynamic-form
              :id="'customer-form-duong'"
              :value="value.street_ids"
              :type="'multi-select'"
              :label="'Đường'"
              value-field="id"
              text-field="name"
              :class="'form-group-hor'"
              :options="streetsByDistricts"
              :select-default="false"
              @update-value="val => value.street_ids = val"
            />

            <!--            đặc biệt-->
            <dynamic-form
              :id="'customer-form-dacbiet'"
              :value="value.has_corner"
              :type="'checkbox'"
              :label="'Đặc biệt'"
              :sub-label="'Sản phẩm có góc'"
              :class="'form-group-hor'"
              @update-value="val => value.has_corner = val"
            />

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >

            <!--            Ngang-->
            <dynamic-form
              :id="'filter-form-ngangtoithieu'"
              :value="value.width"
              :type="'number'"
              placeholder="Ngang tối thiểu (m)"
              :required="required.width ? 'required' : ''"
              :label="'Ngang'"
              :class="'form-group-hor'"
              append-text="≤"
              @update-value="val => value.width = val"
            />

            <!--            Dài-->
            <dynamic-form
              :id="'filter-form-dai'"
              :value="value.length"
              :type="'number'"
              placeholder="Dài tối thiểu (m)"
              :label="'Dài'"
              :class="'form-group-hor'"
              append-text="≤"
              @update-value="val => value.length = val"
            />

            <div class="d-flex mb-1 form-group-hor">
              <label class="minw-100px">Diện tích <span class="text-danger">(*)</span></label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.acreage"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.max_area"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>

            <div
              v-if="value.type!=='san-thuong'"
              class="d-flex mb-1 form-group-hor"
            >
              <label class="minw-100px">Số lầu <span class="text-danger">(*)</span></label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.floor_number"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.max_floor_number"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>

            <div
              v-if="value.type === 'khach-san'"
              class="d-flex mb-1 form-group-hor"
            >
              <label class="minw-100px">Số phòng <span class="text-danger">(*)</span></label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.room_from"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.room_to"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>

            <div class="form-group-hor mb-1">
              <label>Giá (<span class="text-danger">*</span>)</label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.min_price"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.max_price"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
              <div class="d-flex ml-50">
                <v-select
                  id="filter-form-price-from-currency"
                  v-model="value.currency"
                  class="v-select-no-wrap"
                  :reduce="(option) => option.value"
                  :label="'text'"
                  :options="currencyList"
                  :clearable="false"
                />
              </div>
            </div>

            <!--            Thanh toán-->
            <dynamic-form
              :id="'customer-form-type_of_payment'"
              :value="value.type_of_payment"
              :type="'radio'"
              placeholder="Thanh toán"
              :label="'Thanh toán'"
              :required="required.type_of_payment ? 'required' : ''"
              :options="TYPE_OF_PAYMENT.filter(item => item.validBy.includes(value.formality || 'thue'))"
              :class="'form-group-hor'"
              @update-value="val => value.type_of_payment = val"
            />

            <!--            <br>-->
            <!--            <div class="form-group-hor text-primary font-weight-bolder">-->
            <!--              <label class="font-small-4 text-primary"><strong>Giá tổng</strong>-->
            <!--                <feather-icon-->
            <!--                  v-b-tooltip.hover.html="tooltipPriceDisplayCustomer"-->
            <!--                  class="ml-25"-->
            <!--                  icon="AlertCircleIcon"-->
            <!--                />-->
            <!--              </label>-->
            <!--              <div>-->
            <!--                {{ value.priceDisplay }}-->
            <!--              </div>-->
            <!--            </div>-->

          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <!--            chủ nhà-->
            <dynamic-form
              :id="'customer-form-khachhang'"
              :value="value.host_name"
              :type="'text'"
              :label="'Tên Khách + Thương Hiệu'"
              placeholder="Họ tên"
              :required="required.host_name ? 'required' : ''"
              @update-value="val => value.host_name = val"
            />

            <!--            SĐT-->
            <dynamic-form
              :id="'customer-form-sdt1'"
              :value="value.host_phone1"
              :type="'text'"
              :label="'SĐT'"
              :required="required.host_phone1 ? 'required' : ''"
              placeholder="SĐT"
              :class="'form-group-hor'"
              @update-value="val => value.host_phone1 = val"
            />

            <!--            SĐT 2-->
            <dynamic-form
              :id="'customer-form-sdt2'"
              :value="value.host_phone2"
              :type="'text'"
              :label="'SĐT 2'"
              placeholder="SĐT"
              :class="'form-group-hor'"
              @update-value="val => value.host_phone2 = val"
            />

            <!--            nguồn-->
            <dynamic-form
              :id="'product-form-ref'"
              :value="value.source"
              :type="'select'"
              :label="'Nguồn'"
              :options="CUSTOMER_SOURCE"
              :required="required.source ? 'required' : ''"
              placeholder="Nguồn"
              :class="'form-group-hor'"
              @update-value="val => value.source = val"
            />

            <!--              Tình trạng-->
            <dynamic-form
              :id="'customer-form-status'"
              :value="value.status"
              :type="'select'"
              :label="'Tình trạng'"
              :required="required.status ? 'required' : ''"
              :class="'form-group-hor'"
              :options="STATUS_TYPE_CUSTOMER"
              @update-value="val => value.status = val"
            />

            <!--              Ngành nghề-->
            <dynamic-form
              :id="'customer-form-career'"
              :value="value.career"
              :type="'select'"
              :text-field="'name'"
              :value-field="'name'"
              :label="'Ngành nghề'"
              :required="required.career ? 'required' : ''"
              :class="'form-group-hor'"
              :options="careers"
              @update-value="val => value.career = val"
            />

            <!--            Số lần đã gặp-->
            <dynamic-form
              :id="'customer-form-meeting_count'"
              :value="value.meeting_count"
              :type="'text'"
              :label="'Số lần đã gặp'"
              placeholder="Số lần đã gặp"
              :class="'form-group-hor'"
              @update-value="val => value.meeting_count = val"
            />

            <!--            Số lần đã chốt-->
            <dynamic-form
              :id="'customer-form-confirm_deal_count'"
              :value="value.confirm_deal_count"
              :type="'text'"
              :label="'Số lần đã chốt'"
              placeholder="Số lần đã chốt"
              :class="'form-group-hor'"
              @update-value="val => value.confirm_deal_count = val"
            />

            <div class="form-group-hor">
              <label>Hẹn khách</label>
              <flat-pickr
                ref="customer-form-meeting_date"
                v-model="value.meeting_date"
                class="form-control"
                :config="{ locale: Vietnamese, altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </div>

            <dynamic-form
              :id="'customer-form-note'"
              :value="value.note"
              :type="'textarea'"
              :label="'Ghi chú'"
              placeholder="Ghi chú"
              :class="'form-group-hor'"
              @update-value="val => value.note = val"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BButton, VBTooltip, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ref, reactive, computed,
} from '@vue/composition-api'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useCustomerCreate from '@/views/customer/create/useCustomerCreate'
import vSelect from 'vue-select'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    vSelect,
    BBadge,
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BInputGroup,
    BFormInput,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    Vietnamese() {
      return Vietnamese
    },
    currencyList() {
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
    houseStyleList() {
      const style = [
        this.value.terrace ? 'terrace' : '',
        this.value.basement ? 'basement' : '',
        this.value.badger ? 'badger' : '',
        this.value.elevator ? 'elevator' : '',
      ]
      return style || []
    },
    specialList() {
      const style = [
        this.value.is_hot ? 'is_hot' : '',
        this.value.has_corner ? 'has_corner' : '',
        this.value.show_in_web ? 'show_in_web' : '',
      ]
      return style || []
    },
  },
  watch: {
    value: {
      deep: true,
      handler(n) {
        if (!n) return
        this.formErrorText = undefined
        Object.keys(this.required).forEach(key => {
          if (!n[key] && this.required[key]) {
            this.formErrorText = this.formErrorText || []
            this.formErrorText.push(this.required[key])
          }
        })
        this.$emit('update-form-status', this.formErrorText)
      },
    },
  },
  created() {
    this.$call(store.dispatch('app/fetchCareers'))
  },
  methods: {
    getStatusCheckDuplicateBtn() {
      switch (this.customerUniqueStatus) {
        case undefined:
        case null:
          return 'success'
        case '':
          return 'primary'
        default:
          return 'warning'
      }
    },
    handleCheckCustomerUnique() {
      if (!this.value.host_name || !this.value.host_phone1) {
        this.$toastr('Vui lòng nhập tên và số điện thoại khách hàng', 'danger')
        return
      }
      this.$call(this.checkCustomerUnique(this.customer, res => {
        const error = res.data.message || ''
        if (error) {
          this.duplicateError = error
          this.$toastr(error, 'warning')
        } else this.$showNoti('Tên Khách + Thương Hiệu là duy nhất')
        this.customerUniqueStatus = error || ''
        this.$emit('handle-duplicate-error', error)
      }))
    },
    updateByCheckboxGroup(options, checkedList) {
      if (!options.length) return
      options.forEach(item => {
        this.value[item.value] = checkedList.includes(item.value)
      })
    },
  },
  setup(props) {
    const {
      customerMenuType,
      districts,
      wardsByDistricts,
      streetsByDistricts,
      checkCustomerUnique,
    } = useCustomerCreate()

    const careers = computed(() => store.state.app.careers)

    const required = reactive({
      formality: 'Hình thức là bắt buộc',
      type: 'Loại là bắt buộc',
      width: 'Ngang tối thiểu là bắt buộc',
      acreage: 'Diện tích là bắt buộc',
      max_area: 'Diện tích tối đa là bắt buộc',
      floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu là bắt buộc' : '')),
      max_floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu tối đa là bắt buộc' : '')),
      room_from: computed(() => (props.value.type === 'khach-san' ? 'Số phòng tối thiểu là bắt buộc' : '')),
      room_to: computed(() => (props.value.type === 'khach-san' ? 'Số phòng tối đa là bắt buộc' : '')),
      // input_price: 'Giá là bắt buộc',
      type_of_payment: 'Kiểu thanh toán là bắt buộc',
      status: 'Tình trạng là bắt buộc',
      career: 'Ngành nghề là bắt buộc',
      source: 'Nguồn là bắt buộc',
      host_name: 'Tên khách hàng là bắt buộc',
      host_phone1: 'SĐT khách hàng là bắt buộc',
    })

    const tooltipPriceDisplayCustomer = ['[Thanh toán là m2]',
      'Giá tổng = Giá tối đa x Diện tích',
      '[Thanh toán là tổng/tháng]',
      'Giá tổng = Giá tối đa nhập',
      '[Diện tích]',
      'Diện tích = Ngang tối thiểu x Dài tối thiểu x (Lầu tối thiểu + 1)',
    ].join('<br/>')

    const customer = reactive({
      host_name: computed(() => props.value.host_name),
      host_phone1: computed(() => props.value.host_phone1),
    })
    const customerUniqueStatus = ref(undefined) // [undefined, '', error]
    const duplicateError = ref('')

    return {
      customerMenuType,
      duplicateError,
      required,
      customer,
      customerUniqueStatus,
      districts,
      wardsByDistricts,
      streetsByDistricts,
      tooltipPriceDisplayCustomer,
      careers,
      //
      checkCustomerUnique,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
