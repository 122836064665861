import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import useCustomer from '@/views/customer/useCustomer'
import util from '@/global/util'
import CustomerModal from '@/modal/customerModal'
import constant from '@/global/const'
import http from '@/global/http'

export default function useCustomerCreate() {
  const {
    customerMenuType,
    renderCustomerTitle,
    renderCustomerContent,
    //
    checkCustomerUnique,
    createCustomer,
    fetchCustomer,
    updateCustomer,
  } = useCustomer()

  const duplicateError = ref(null)
  const formBaseStatus = ref([])

  const customer = CustomerModal()

  const districts = computed(() => store.state.app.districts)
  const wardsByDistricts = computed(() => store.state.app.wardsByDistricts)
  const streetsByDistricts = computed(() => store.state.app.streetsByDistricts)

  watch(() => customer.formality, n => {
    if (!n) return
    customer.currency = 'million'
  })
  watch(() => customer.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
  })
  watch(() => customer.district_ids, n => {
    store.dispatch('app/fetchStreetsByDistricts', http.buildQuery({ district_ids: n }))
    store.dispatch('app/fetchWardsByDistricts', http.buildQuery({ district_ids: n }))
  })
  watch([() => customer.width, () => customer.length, () => customer.floor_number], n => {
    const w = parseFloat(n[0] || 0, 10)
    const l = parseFloat(n[1] || 0, 10)
    const f = parseFloat(n[2] || 0, 10)
    if (!w || !l) return
    customer.acreage = w * l * (f + 1)
  }, { immediate: true })
  watch([() => customer.acreage, () => customer.input_price, () => customer.currency, () => customer.type_of_payment], ([a, p, c, t]) => {
    const val = t === 'm2' ? a * p : p
    customer.price = val
    if (c.toLowerCase() === 'million') {
      customer.price = val * 1000000
    }
    if (c.toLowerCase() === 'billion') {
      customer.price = val * 1000000000
    }
    customer.priceDisplay = `${util.formatNumber(val || 0)} ${constant.CURRENCY.find(item => item.value === c)?.text?.toUpperCase()}`
  })
  watch(() => customer.transfer_price, n => {
    customer.transferPriceDisplay = `${util.formatNumber(n || 0)} ${constant.CURRENCY.find(item => item.value === (customer.transfer_price_currency || 'usd'))?.text?.toUpperCase()}`
  })

  return {
    districts,
    wardsByDistricts,
    streetsByDistricts,
    customer,
    customerMenuType,
    duplicateError,
    formBaseStatus,
    renderCustomerTitle,
    renderCustomerContent,
    //
    checkCustomerUnique,
    fetchCustomer,
    createCustomer,
    updateCustomer,
  }
}
